import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React from "react"
import { SEO } from "../../components/seo"
import { useRef } from "react"
import { useEffect } from "react"
import { caseStudies } from "."
import ShowCase from "../../components/CaseStudy/CaseStudyShowCase"
import Overview from "../../components/CaseStudy/Overview"
import SovaBanner from "../../components/CaseStudy/SovaBanner"
import ContentImage from "../../components/ContentImage/ContentImage"
import Newsletter from "../../components/Form/Newsletter"
import Hello from "../../components/Hello/Hello"
import HeroTwo from "../../components/Hero/HeroTwo"
import Layout from "../../components/layout"
import StaticTestimonial from "../../components/Testimonial/StaticTesimonial"
import TitleContent from "../../components/TitleContent/TitleContent"
import { StaticImage } from "gatsby-plugin-image"
import {
    SovaImageFive,
    SovaImageFour,
    SovaImageOne,
    SovaImageThree,
    SovaImageTwo
} from "../../components/CaseStudy/SovaImage"

gsap.registerPlugin(ScrollTrigger)

const techs = [
    { src: "/case-study/next.svg" },
    { src: "/case-study/strapi.svg" },
    { src: "/case-study/netlify.svg" },
    { src: "/case-study/react.svg" },
    { src: "/case-study/tailwind.svg" }
]

const overview = {
    overview: `Sova Assessment wanted to revamp their website, which was based on an old school, outdated monolithic technology stack. The goal was to create a new website that not only looked modern and appealing but also performed exceptionally well. 
    Moreover, they wanted to shift from their old CMS (CraftCMS) to a more modern architecture that offered flexibility and security for years to come.`,
    services: ["Development", "UI/UX", "SEO"],
    linkUrl: "https://sovaassessment.com/"
}

const lhScores = [
    {
        title: "Performance",
        score: 97
    },
    {
        title: "Accessibility",
        score: 96
    },
    {
        title: "Best Practices",
        score: 92
    },
    {
        title: "SEO",
        score: 100
    }
]

const sliderImages = [
    {
        text: "Cognitive tests gain valuable insight by measuring a candidates’s ablitiy",
        component: SovaImageOne
    },
    {
        text: "Future-proof your professional hiring",
        component: SovaImageTwo
    },
    {
        text: "Situational judgement test - Assessing how candidates respond",
        component: SovaImageThree
    },
    {
        text: "Digital assement centre",
        component: SovaImageFour
    }
]

const tlDefaults = {
    ease: "slow.inOut",
    duration: 1.25
}

const Detail = ({ location }) => {
    const $contentImageWrapper = useRef(null)
    const $contentImagePin = useRef(null)

    const $sliderSection = useRef(null)
    const $slider = useRef(null)
    const $progress = useRef(null)

    const currentSlide = useRef(undefined)
    const next = useRef(0)
    const alreadyEntered = useRef(false)

    const heroData = {
        preheader: "Sova Assessment",
        title: "Changing assessment for good"
    }
    const context = {
        pageName: "SD | Case studies - Sovaassessment",
        pageUri: location.href
    }

    useEffect(() => {
        const mm = gsap.matchMedia()

        mm.add("(min-width: 1024px)", () => {
            const contentImages =
                $contentImageWrapper.current.querySelectorAll(".content-image")

            contentImages[0].classList.add("is-active")

            ScrollTrigger.create({
                trigger: $contentImageWrapper.current,
                // pin: $contentImagePin.current,
                start: "top top",
                onLeave: () => {
                    contentImages[0].classList.remove("is-active")
                    contentImages[contentImages.length - 1].classList.add(
                        "is-active"
                    )
                },
                // markers: true,
                onUpdate: self => {
                    const progress = Number(self.progress.toFixed(3))
                    const total = contentImages.length + 1

                    contentImages.forEach((_, i) => {
                        const oldIndex = i
                        const newIndex = oldIndex + 1

                        if (
                            progress > oldIndex / total &&
                            progress < newIndex / total
                        ) {
                            contentImages[oldIndex].classList.add("is-active")

                            if (self.direction === 1 && oldIndex > 0) {
                                contentImages[oldIndex - 1].classList.remove(
                                    "is-active"
                                )
                            } else if (
                                self.direction === -1 &&
                                newIndex < contentImages.length
                            ) {
                                contentImages[newIndex].classList.remove(
                                    "is-active"
                                )
                            }
                        }
                    })
                }
            })
        })

        return () => {
            mm.revert()
        }
    }, [])

    useEffect(() => {
        const ctx = gsap.context(() => {
            const outers = gsap.utils.toArray(".slider-outer")
            const inners = gsap.utils.toArray(".slider-inner")
            const images = gsap.utils.toArray(".slider-image")

            gsap.set(outers, { yPercent: 100 })
            gsap.set(inners, { yPercent: -100 })
            gsap.set(images, { scale: 1.3, yPercent: 15 })
            gsap.set($progress.current, { scaleX: 0 })

            ScrollTrigger.create({
                trigger: $sliderSection.current,
                start: "top top",
                // markers: true,
                onLeave: () => {
                    console.log("leaving")
                },
                onEnter: () => {
                    if (!alreadyEntered.current) {
                        slideIn()

                        gsap.fromTo(
                            ".slider-logo",
                            { autoAlpha: 0 },
                            { autoAlpha: 1, duration: 1.5, delay: 1 }
                        )
                    }

                    alreadyEntered.current = true
                }
            })
        })

        return () => {
            ctx.revert()
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentSlide.current !== undefined) {
            }
        }, 4000)

        return () => {
            clearInterval(interval)
        }
    }, [])
    // Slides a section in on scroll down
    function slideIn() {
        const outers = gsap.utils.toArray(".slider-outer")
        const inners = gsap.utils.toArray(".slider-inner")
        const images = gsap.utils.toArray(".slider-image")
        const texts = gsap.utils.toArray(".slider-text")

        // The first time this function runs, currentSlide is undefined
        const tl = gsap.timeline({
            paused: true,
            defaults: tlDefaults,
            onComplete: () => {
                currentSlide.current = next.current
                next.current = currentSlide.current + 1
                if (next.current > sliderImages.length - 1) {
                    currentSlide.current = sliderImages.length - 1
                    next.current = 0
                }

                const timeout = setTimeout(() => {
                    slideIn()
                }, 2000)
            }
        })

        tl.to(outers[next.current], { yPercent: 0 }, 0)
            .to(inners[next.current], { yPercent: 0 }, 0)
            .to(images[next?.current], { yPercent: 0, scale: 1 }, 0)
            .to(
                $progress.current,
                { scaleX: (next.current + 1) / sliderImages.length },
                0
            )
            .fromTo(
                texts[next?.current],
                { y: 40, opacity: 0 },
                { y: 0, opacity: 1 },
                0
            )

        if (currentSlide?.current !== undefined) {
            tl.to(outers[currentSlide.current], { yPercent: -100 }, 0)
                .to(inners[currentSlide.current], { yPercent: 100 }, 0)
                .to(
                    images[currentSlide.current],
                    { scale: 1.2, yPercent: -15 },
                    0
                )
                .to(
                    texts[currentSlide?.current],
                    { y: -40, opacity: 0, duration: 0.8 },
                    0
                )

            tl.add(
                gsap
                    .timeline()
                    .set(outers[currentSlide?.current], {
                        yPercent: 100
                    })
                    .set(inners[currentSlide?.current], {
                        yPercent: -100
                    })
                    .set(images[currentSlide?.current], {
                        yPercent: 15,
                        scale: 1.3
                    })
            )
        }

        tl.play(0)
    }

    return (
        <Layout context={context}>
            <HeroTwo props={heroData} />
            {/* Overview */}
            <Overview {...overview} />
            {/* banner */}
            <SovaBanner className={"mt-20 lg:mt-24"}>
                <div className="relative w-full lg:max-w-[50%]">
                    <div className="w-full sm:hidden">
                        <StaticImage
                            src={
                                "../../../static/case-study/sova-mockup-mobile.png"
                            }
                            alt="Sova mockup"
                        />
                    </div>

                    <div className="absolute top-1/2 hidden w-full -translate-y-[57%] object-cover sm:block lg:hidden">
                        <StaticImage
                            src="../../../static/case-study/sova-mockup-tablet.png"
                            alt=""
                        />
                    </div>

                    <div className="banner-image absolute top-1/2 hidden w-full object-cover will-change-transform lg:block">
                        <StaticImage
                            src="../../../static/case-study/sova-mockup-desktop.png"
                            alt=""
                        />
                    </div>
                </div>
            </SovaBanner>

            <TitleContent
                title="An Experience Platform with an Experienced Tech Stack"
                className="py-14 lg:py-24"
            >
                <p className="mb-4">
                    Sova Assessment's new website is built using cutting-edge
                    technologies to deliver a seamless user experience and
                    streamline content management.
                </p>
                <ul className="mb-6 list-disc pl-5">
                    <li>
                        The JAMstack architecture separates frontend and
                        backend, improving performance and security.
                    </li>
                    <li>
                        Next.js with TypeScript ensures faster page loads and
                        robust code.
                    </li>
                    <li>
                        Tailwind CSS allows rapid UI development, while
                        Storybook aids in building and testing components.
                    </li>
                    <li>
                        GSAP adds captivating animations to engage users, and
                        Strapi as a Headless CMS enables flexible content
                        management.
                    </li>
                    <li>
                        GraphQL optimises data fetching, and Netlify provides
                        seamless hosting and scalability. Integration with
                        HubSpot and Chili Piper enhances customer engagement and
                        lead management.
                    </li>
                </ul>
                <h3 className="mt-6 text-lg font-semibold">
                    Technologies used:
                </h3>
                <div class="mt-6 grid grid-cols-2 gap-4 md:gap-4 lg:grid-cols-3 lg:gap-[30px]">
                    {techs.map(({ src }, index) => {
                        return (
                            <div class="group grid aspect-[160/78] place-items-center border py-2 px-6 transition-all duration-300 hover:shadow-light_shadow">
                                <img
                                    src={src}
                                    className="max-w-full object-contain grayscale transition-all duration-300 group-hover:grayscale-0"
                                    alt="logos"
                                />
                            </div>
                        )
                    })}
                </div>
            </TitleContent>

            <Hello
                link="https://saigon.digital/blog/advancing-web-security-jamstack-versus-traditional-website-development"
                title="Optimise User-Experience with Google Lighthouse"
                lhScores={lhScores}
            >
                <p className="mb-4">
                    Google Lighthouse is an open-source tool for website
                    performance, auditing pages for performance, accessibility,
                    SEO, Progressive Web App and Best Practices.
                </p>
                <p className="mb-4">
                    Using Google Lighthouse as a benchmark, we make sure that
                    Sova's website is performing flawlessly with excellent
                    scores.
                </p>
                <p>
                    Here is the performance results:{" "}
                    <a
                        href="/sova-lighthouse-report.html"
                        target="_blank"
                        download
                        className="font-semibold text-primary-color underline"
                    >
                        Google Lighthouse Report
                    </a>
                </p>
            </Hello>

            <div className="h-screen" ref={$sliderSection}>
                <div className="flex min-h-screen flex-col pt-14 lg:pt-24">
                    <TitleContent
                        title="The Challenge"
                        className={"mb-10 lg:mb-16"}
                    >
                        <p>
                            Sova Assessment wanted to revamp their website,
                            which was based on an old school, outdated
                            monolithic technology stack. The goal was to create
                            a new website that not only looked modern and
                            appealing but also performed exceptionally well.
                            Moreover, they wanted to shift from their old CMS
                            (CraftCMS) to a more modern architecture that
                            offered flexibility and security for years to come.
                        </p>
                    </TitleContent>

                    <div className="relative flex-1 overflow-hidden">
                        <div className="absolute inset-0">
                            <div
                                className="mandala-slider relative h-full w-full overflow-hidden will-change-transform"
                                ref={$slider}
                            >
                                {sliderImages.map((image, index) => {
                                    const ImageComponent = image.component
                                    return (
                                        <div
                                            className="slider-wrapper absolute inset-0"
                                            style={{
                                                zIndex: 10 - index
                                            }}
                                        >
                                            <div className="slider-outer relative h-full  w-full overflow-hidden will-change-transform">
                                                <div className="slider-inner absolute inset-0 will-change-transform">
                                                    <div
                                                        className={`slider-image relative h-full w-full bg-cover bg-center will-change-transform`}
                                                    >
                                                        <ImageComponent />
                                                        <div className="absolute inset-0 bg-black/40"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="absolute inset-0 z-10">
                                                <div className="relative top-1/3 w-full">
                                                    <div className="container">
                                                        <StaticImage
                                                            src="../../../static/case-study/sova.svg"
                                                            className="slider-logo invisible mb-6"
                                                            objectFit="contain"
                                                        />
                                                        <h2 className="slider-text max-w-md text-2xl font-semibold tracking-wide text-white lg:max-w-lg lg:text-[32px] lg:leading-[45px]">
                                                            {image.text}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div
                            className="absolute bottom-0 left-0 h-1 w-full origin-left bg-white"
                            ref={$progress}
                        ></div>
                    </div>
                </div>
            </div>

            {/* approach */}
            <TitleContent
                title="The Approach"
                className="mt-14 mb-10 lg:mt-24 lg:mb-16"
            >
                <p className="">
                    Saigon Digital stepped in to collaborate with Sova,
                    implementing a new tech stack, including NextJS for the
                    frontend and Strapi CMS for the backend. Focusing on
                    creating beautiful, user-friendly UI components, the team
                    aimed to enhance the user experience and site performance.
                </p>
            </TitleContent>
            {/* Solution */}
            <TitleContent className="mb-10 lg:mb-16" title="The Solutions">
                <p className="mb-4">
                    Saigon Digital and Sova Assessment came together and
                    discussed the idea of using a Headless CMS alongside the
                    JAMstack. Strapi seemed to be the perfect fit based on the
                    requirements from the Content Editor and functionality. 
                </p>
                <p>
                    Other than the technical requirements, we got to work and
                    carried out a Discovery Phase which included researching the
                    target audience, understanding the content and key goals of
                    the new website. From here our Design team went away and
                    came up with a fresh new look for the Sova Assessment
                    website. A library of over 30 reusable components were
                    translated from content ideas and wireframes into fully
                    functional web UI components that the Sova team can reuse
                    across their new site inside Strapi CMS.
                </p>
            </TitleContent>
            {/* banner 2 */}
            <div className="container mb-10 grid gap-y-10 gap-x-[30px] md:grid-cols-2 lg:mb-16 lg:gap-x-24">
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:ml-5 ">
                    <StaticImage
                        src="../../../static/case-study/banner-2.png"
                        className="h-full w-full duration-1000 hover:scale-105"
                        alt=""
                        objectFit="cover"
                    />
                </div>
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:mr-5 ">
                    <StaticImage
                        src="../../../static/case-study/banner-3.png"
                        className="h-full w-full object-cover transition-transform duration-1000 hover:scale-105"
                        alt=""
                        objectFit="cover"
                    />
                </div>
            </div>

            <Newsletter context={context} />

            {/* Out Come */}
            <TitleContent
                title="Outcomes"
                className={"mb-10 mt-14 lg:mb-16 lg:mt-24"}
            >
                <p>
                    The partnership resulted in a top-notch website that
                    embodies Sova's mission and commitment to improving talent
                    assessment efficiency. The new site offers an optimised user
                    experience, enhanced site performance, and a modern UI/UX
                    that strengthens Sova's brand in the recruitment software
                    industry. The modular and scalable architecture ensures the
                    site can handle future growth, delivering consistent,
                    high-quality performance and empowering Sova’s team to
                    publish content like a breeze.
                </p>
            </TitleContent>
            {/* Content image wrapper */}
            <div ref={$contentImageWrapper}>
                <div
                    className="relative lg:sticky lg:top-[20vh] lg:h-screen"
                    ref={$contentImagePin}
                >
                    <ContentImage
                        className="lg:opacity-100"
                        img={"/case-study/sova-content-image-1.png"}
                    >
                        <h2 className="mb-4 text-2xl font-semibold">
                            Technology Transformation
                        </h2>

                        <ul className="list-disc pl-5">
                            <li>
                                From the old CMS to the new modern JAMstack
                                architechure.
                            </li>
                            <li>
                                A modern and friendly UI UX component to improve
                                the brand's interface and marketing activities.{" "}
                            </li>
                            <li>
                                The JAMstack architecture allows for the
                                separation of the frontend and backend, enabling
                                the website to leverage static site generation
                                or server-side rendering. This results in faster
                                initial page loads, which is crucial for
                                improving user experience and reducing bounce
                                rates.
                            </li>
                        </ul>
                    </ContentImage>
                    <ContentImage img={"/case-study/sova-content-image-2.png"}>
                        <h2 className="mb-4 text-2xl font-semibold">
                            HubSpot & Chilipiper
                        </h2>

                        <ul className="list-disc pl-5">
                            <li>
                                Combining HubSpot and Chilipiper allowed for
                                real-time appointment scheduling which has
                                streamlined the process for potential customers,
                                while automated reminders ensured meetings were
                                well-prepared.
                            </li>
                            <li>
                                The integration facilitated personalised
                                engagement, enabling Sova’s sales reps to access
                                lead data from HubSpot during interactions. This
                                approach has significantly improved lead
                                generation and customer interactions on the new
                                Sova Assessment website.
                            </li>
                        </ul>
                    </ContentImage>
                    <ContentImage img={"/case-study/sova-content-image-3.png"}>
                        <h2 className="mb-4 text-2xl font-semibold">
                            A Collaborative UI Library
                        </h2>

                        <ul className="list-disc pl-5">
                            <li>
                                Saigon Digital utilised Storybook, a tool for
                                developing and testing UI components.
                            </li>
                            <li>
                                This resulted in the creation of visually
                                appealing, user-friendly components that
                                enhanced the collaboration between the
                                stakeholders.
                            </li>
                        </ul>
                    </ContentImage>
                    <ContentImage img={"/case-study/sova-content-image-4.png"}>
                        <h2 className="mb-4 text-2xl font-semibold">
                            Dynamic Page Builder
                        </h2>

                        <ul className="list-disc pl-5">
                            <li>
                                Saigon Digital empowered Sovas Marketing team to
                                create and manage engaging and dynamic pages
                                without relying on technical expertise.
                            </li>
                            <li>
                                This approach has given the Sova team the
                                freedom to experiment with content layouts and
                                unique content styles, making the website more
                                appealing to visitors and effectively showcasing
                                Sova Assessment's key areas of service in an
                                engaging and dynamic manner.
                            </li>
                        </ul>
                    </ContentImage>
                </div>
                <div className="lg:h-[400vh]"></div>
            </div>

            <TitleContent
                title="The Results"
                className={"mb-10 lg:mb-16 lg:-mt-[30vh]"}
            >
                <p className="mb-4">
                    The project was a resounding success. The new website met
                    all of Sova Assessment's expectations, helping improve their
                    brand interface and marketing. With a modern, user-friendly
                    UI/UX and a high-performance score, the website is poised to
                    help their brand grow further in their industry online. 
                </p>
                <p>
                    We’re now looking forward to growing and building on the
                    platform we’ve established in this initial Project. Future
                    development planned would include localised content, more
                    page modules to enrich the Content Editors freedom and more!
                </p>
            </TitleContent>

            <div className="grid bg-[#1F0A7D] lg:grid-cols-2">
                <div className="aspect-video lg:aspect-auto">
                    <StaticImage
                        src="../../../static/case-study/sova-banner-2.png"
                        className="h-full w-full"
                        objectFit="cover"
                    />
                </div>

                <div className="container flex flex-col justify-center py-20 text-white lg:px-10 xl:px-20 xl:py-28">
                    <h2 className="mb-2 text-2xl font-semibold lg:text-3xl">
                        Revolutionise your hiring process
                    </h2>
                    <p>
                        Unlock the potential of your workforce with Sova's full
                        stack talent assessment platform, featuring skill
                        assessments, behavioural assessments, and cognitive
                        ability testing.
                    </p>
                </div>
            </div>
            <StaticTestimonial
                title={"Thoughts from the Sova Team"}
                content={
                    <>
                        <p className="mb-4">
                            We had the privilege of working with Saigon Digital
                            on our website, and it was an outstanding experience
                            from start to finish. Their team of professionals
                            demonstrated exceptional expertise, delivering a
                            top-notch website that exceeded our expectations.
                        </p>
                        <p>
                            Throughout the collaboration, their reliability,
                            efficient turnaround time, and open communication
                            allowed for a seamless and productive partnership.
                            We highly recommend Saigon Digital for their
                            excellent output, great collaboration, and reliable
                            service
                        </p>
                    </>
                }
                author={"Shahzad Ahmed"}
                position="Chief Growth Officer"
            />
            <ShowCase data={caseStudies} />
        </Layout>
    )
}

export default Detail

export const Head = () => (
    <SEO
        title={"Sova Assessment | Saigon Digital"}
        description={
            "Sova Assessment's new website is built using cutting-edge technologies to deliver a seamless user experience and streamline content management."
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
    />
)
