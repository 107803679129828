import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const SovaImageOne = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/sova-slider-1.png"}
                alt="sova mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

const SovaImageTwo = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/sova-slider-2.png"}
                alt="sova mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

const SovaImageThree = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/sova-slider-3.png"}
                alt="sova mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

const SovaImageFour = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/sova-slider-4.png"}
                alt="sova mockup"
                loading="eager"
                objectFit="cover"
                className="h-full w-full"
            />
        </div>
    )
}

export { SovaImageOne, SovaImageTwo, SovaImageThree, SovaImageFour }
