import Blob from "@images/blobanimation.svg"
import React, { useEffect } from "react"
import { useRef } from "react"
import styled from "styled-components"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.section`
    background: linear-gradient(91deg, #180f7d 0%, #27007a 100%);
`

if (typeof window !== "undefinded") {
    gsap.registerPlugin(ScrollTrigger)
}
function SovaBanner({ className, startColor, endColor, children }) {
    const $wrapper = useRef(null)
    const $logo = useRef(null)
    const $title = useRef(null)

    useEffect(() => {
        const mm = gsap.matchMedia($wrapper)
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: $wrapper.current,
                once: true,
                // markers: true,
                start: "top bottom-=50%",
                end: "top bottom-=50%"
            }
        })
        mm.add("(min-width: 1024px)", () => {
            tl.from($logo.current, {
                y: 40,
                opacity: 0,
                stagger: 0.5
            })

            tl.from(
                $title.current,
                {
                    y: 40,
                    opacity: 0,
                    duration: 0.5,
                    ease: "back"
                },
                0
            )

            gsap.fromTo(
                ".banner-image",
                { yPercent: -55 },
                {
                    yPercent: -45,
                    scrollTrigger: {
                        trigger: $wrapper.current,
                        scrub: 0.8,

                        // markers: true,
                        start: "top bottom-=20%",
                        end: "bottom+=100% top"
                    },
                    duration: 1,
                    ease: "none"
                }
            )
        })

        return () => {
            mm.revert()
        }
    }, [])
    return (
        <Wrapper
            $startColor={startColor}
            $endColor={endColor}
            className={`relative ${className} overflow-y-hidden`}
            ref={$wrapper}
        >
            <div
                className={`brand-banner container relative flex flex-col gap-6 overflow-hidden py-14 sm:min-h-[460px] sm:flex-row sm:items-stretch lg:justify-between`}
            >
                <div className="flex flex-col items-center gap-6 text-center sm:max-w-[175px] sm:items-start sm:justify-center sm:text-left md:max-w-none lg:max-w-[500px]">
                    <div className="w-[180px] lg:mb-8 lg:w-[190px]" ref={$logo}>
                        <img src={"/case-study/sova.svg"} alt="sova logo" />
                    </div>

                    <h2
                        className="max-w-sm text-xl font-semibold text-white sm:max-w-none lg:text-[32px] lg:leading-snug"
                        ref={$title}
                    >
                        Full stack talent assessment for enterprise hiring
                    </h2>
                </div>

                {children}
               
            </div>
        </Wrapper>
    )
}

export default SovaBanner
